import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Button, Dropdown, Menu, message, Icon, Divider, Input, Select, Carousel, Pagination, LocaleProvider, Modal, Form, Breadcrumb, DatePicker, Cascader, Tabs, Checkbox, Upload, Radio, Collapse, Spin, Table, Row, Col, Popover, Drawer } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less'
import VueCarousel from 'vue-carousel';
import './theme-file.less'
import "@/assets/iconfont/iconfont.css"
import '@/assets/iconfont/iconfont.js'

Vue.use(VueCarousel);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Carousel);
Vue.use(Pagination);
Vue.use(LocaleProvider);
Vue.use(Modal);
Vue.use(Breadcrumb);
Vue.use(Form);
Vue.use(DatePicker);
Vue.use(Cascader);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);
Vue.use(Popover);
Vue.use(Drawer);
Vue.component(Spin.name, Spin)
Vue.prototype.$message = message;
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  // 加入百度统计
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  if (to.meta.title) { //拦截后设置标题
    document.title = to.meta.title
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }
    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签
    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签
    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
      contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
    }
  }
  next()
})
