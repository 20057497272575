<template>
  <div>
    <div class="homeFooter">
      <div class="topBox">
        <router-link to="/easyMerchants">
          <div class="image-container"><img src="@/assets/ZY_yewei_yizhaotong.png"></div>
          <div>
            <span>易招通</span>
            <span>一切为招商而来</span>
          </div>
        </router-link>
        <router-link to="/tiktok">
          <div class="image-container"><img src="@/assets/ZY_yewei_tt.png"></div>
          <div>
            <span>抖音竞价</span>
            <span>多渠道全网营销</span>
          </div>
        </router-link>
        <router-link to="/shopAbout">
          <div class="image-container"><img src="@/assets/ZY_yewei_clouds.png"></div>
          <div>
            <span>云商铺</span>
            <span>B2B线上货柜</span>
          </div>
        </router-link>
        <router-link to="/newsList?id=642179999336855">
          <div class="image-container"><img src="@/assets/ZY_yewei_xunzhan.png"></div>
          <div>
            <span>行业巡展</span>
            <span>买卖双方面对面交流</span>
          </div>
        </router-link>
        <router-link to="/newsList?id=654485439158098">
          <div class="image-container"><img src="@/assets/ZY_yewei_shenghui.png"></div>
          <div>
            <span>品牌盛会</span>
            <span>打造企业品牌形象</span>
          </div>
        </router-link>
        <router-link to="/superConditions">
          <div class="image-container"><img src="@/assets/ZY_yewei_shanq.png"></div>
          <div>
            <span>超级商情</span>
            <span>为企业开疆拓土</span>
          </div>
        </router-link>
      </div>
      <div class="middleBox">
        <div>
          <div class="image-container"><img src="@/assets/ZY_yewei_logo.png"></div>
          <div class="urlItem">
            <router-link to="/about">关于我们</router-link>
            <div class="zhiCustomBtn">联系我们</div>
          </div>
          <div class="urlItem">
            <div>法律声明</div>
            <div>网站地图</div>
          </div>
        </div>
        <div>
          <div>友情链接</div>
          <div class="urlBox">
            <div class="urlItem">
              <a href="https://www.cm.hczyw.com/" target="_blank">慧聪工程机械网</a>
              <a href="https://www.edu.hczyw.com/" target="_blank">慧聪教育网</a>
              <a href="https://www.water.hczyw.com/" target="_blank">慧聪水工业网</a>
              <a href="https://www.dj.hczyw.com/" target="_blank">商业展示</a>
            </div>
            <div class="urlItem">
              <a href="https://www.audio.hczyw.com/" target="_blank">慧聪音响灯光网</a>
              <a href="https://www.fire.hczyw.com/" target="_blank">慧聪消防网</a>
              <a href="https://www.secu.hczyw.com/" target="_blank">慧聪物联网</a>
            </div>
            <div class="urlItem">
              <a href="https://www.ledp.hczyw.com/" target="_blank">慧聪LED屏网</a>
              <a href="https://www.qipei.hczyw.com/" target="_blank">慧聪汽服网</a>
              <a href="https://www.hotel.hczyw.com/" target="_blank">慧聪酒店网</a>
            </div>
            <div class="urlItem">
              <a href="https://www.hvacr.hczyw.com/" target="_blank">慧聪暖通与舒适家居网</a>
              <a href="https://www.homea.hczyw.com/" target="_blank">慧聪家电网</a>
              <a href="https://www.ibuychem.com/" target="_blank">买化塑</a>
            </div>
          </div>
        </div>
        <div class="QRBox">
          <div>
            <div class="image-container"><img src="@/assets/QR.png"></div>
            <div>资源网移动端</div>
          </div>
          <div>
            <div class="image-container"><img src="@/assets/gzh.jpg"></div>
            <div>资源网公众号</div>
          </div>
          <div>
            <div class="image-container"><img src="@/assets/xcx.jpg"></div>
            <div>资源网小程序</div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div>
          <div>广州慧聪叁陆零网络科技有限公司</div>
          <div>慧聪行业资源网www.hczyw.com <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2021157007号</a> 粤公网安备44010402003188号</div>
        </div>
        <div>
          <div class="image-container"><img src="@/assets/ZY_yewei_360.png"></div>
          <div class="image-container"><img src="@/assets/ZY_yewei_xing.png"></div>
          <div class="image-container"><img src="@/assets/ZY_yewei_quanguo.png"></div>
          <div class="image-container"><img src="@/assets/ZY_yewei_anquanshu.png"></div>
        </div>
      </div>
    </div>
    <div class="mobileFooter">
      <div class="image-container"><img src="@/assets/ZY_yewei_logo.png"></div>
      <div>广州慧聪叁陆零网络科技有限公司</div>
      <div>慧聪行业资源网www.hczyw.com</div>
      <div><img class="police" src="@/assets/police.png">公安备案号：粤公网安备44010402003188号</div>
      <div>
        <div class="image-container"><img src="@/assets/ZY_yewei_360.png"></div>
        <div class="image-container"><img src="@/assets/ZY_yewei_xing.png"></div>
        <div class="image-container"><img src="@/assets/ZY_yewei_quanguo.png"></div>
        <div class="image-container"><img src="@/assets/ZY_yewei_anquanshu.png"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  props: {},
  data: function () {
    return {};
  },
  mounted: function () {
    
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1025px) {
  .mobileFooter{
    display:none;
  }
  .homeFooter {
    background-color: #222222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 1248px;
    padding:0 24px;
    .topBox {
      background-color: #222222;
      width: 1200px;
      display: inline-flex;
      border-bottom: 1px solid #363636;
      > a {
        width: 200px;
        height: 112px;
        color: var(--white);
        display: flex;
        align-items: center;
        text-align: left;
        cursor: pointer;
        img {
          width: 48px;
          height: 48px;
          margin-right: 6px;
        }
        > div > span {
          display: block;
        }
        > div > span:first-child {
          font-size: 16px;
        }
        > div > span:last-child {
          font-size: 12px;
        }
      }
    }
    .middleBox {
      background-color: #222222;
      padding: 33px 0 30px 0;
      border-bottom: 1px solid #363636;
      width: 1200px;
      display: inline-flex;
      text-align: left;
      > div:first-child {
        width: 226px;
        img {
          width: 125px;
          height: 25px;
          margin-bottom: 25px;
        }
        .urlItem {
          display: flex;
          font-size: 12px;
          > div,
          > a {
            margin-bottom: 13px;
            margin-right: 38px;
            color: #999;
            cursor: pointer;
          }
          > div:hover,
          > a:hover {
            color: var(--white);
            transition: all 0.3s;
          }
        }
      }
      > div:nth-child(2) {
        > div:first-child {
          color: var(--white);
          font-size: 16px;
          display: flex;
          height: 28px;
          align-items: center;
          margin-bottom: 20px;
        }
        .urlBox {
          display: flex;
          .urlItem {
            margin-right: 58px;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            > a {
              color: #999;
              cursor: pointer;
              margin-bottom: 13px;
            }
            > a:hover {
              color: var(--white);
              transition: all 0.3s;
            }
          }
        }
      }
      .QRBox {
        background-color: #222222;
        display: flex;
        margin-left: 29px;
        > div {
          color: var(--white);
          margin-left: 20px;
          img {
            width: 98px;
            height: 98px;
          }
          > div {
            font-size: 12px;
            margin-top: 8px;
            text-align: center;
          }
        }
      }
    }
    .bottomBox {
      width: 1200px;
      padding: 25px 0;
      display: inline-flex;
      justify-content: space-between;
      > div:first-child {
        color: #999999;
        font-size: 12px;
        text-align: left;
        height: 41px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        bottom: 5px;
        > div {
          height: 14px;
          a{
            color:#999;
          }
        }
      }
      > div:last-child {
        img {
          height: 34px;
          margin-left: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .noHeader{
    display: none;
  }
  .homeFooter {
    display:none;
  }
  .mobileFooter{
    background-color: #222222;
    padding:16px;
    text-align:left;
    color:#999;
    >.image-container{
      >img{
        width: 88px;
        height: 16px;
      }
    }
    >div{
      font-size: 12px;
      margin-bottom:10px;
      display:flex;
      >.police{
        width:16px;
        height:16px;
      }
      .image-container{
        img{
          width: 65px;
          height: 24px;
          margin-right:10px;
        }
      }
    }
  }
}
</style>